import React, { ReactNode, useEffect, useState } from 'react'
import PageLoadContext from '../context/pageLoadContext'
import { initGAnalytics } from '../services/google-analytics'
import useCookieConsent from '../hooks/use-cookie-consent'

interface RootWrapperProps {
    children: ReactNode
}

const RootWrapper = ({ children }: RootWrapperProps) => {
    const [hasLoaded, setHasLoaded] = useState(false)

    useCookieConsent()

    useEffect(() => {
        initGAnalytics()

        const onPageLoad = () => {
            setHasLoaded(true)
        }

        if (document.readyState === 'complete') {
            onPageLoad()
        } else {
            window.addEventListener('load', onPageLoad)
            return () => window.removeEventListener('load', onPageLoad)
        }
    }, [])

    return (
        <PageLoadContext.Provider value={hasLoaded}>
            {children}
        </PageLoadContext.Provider>
    )
}

export default RootWrapper
