// Global page options
import React from 'react'
import { ShouldUpdateScrollArgs, WrapRootElementBrowserArgs } from 'gatsby'
import RootWrapper from './src/components/root-wrapper'
import './src/styles/global.scss'

// Always start at top of page (unless arriving via an anchor link)
export const shouldUpdateScroll = ({ routerProps }: ShouldUpdateScrollArgs) => {
    const hasAnchor = !!routerProps.location.hash // (ie. /#features)

    if (!hasAnchor) {
        window.scrollTo(0, 0)
    }

    return hasAnchor
}

export const wrapRootElement = ({ element }: WrapRootElementBrowserArgs) => {
    return (
        <RootWrapper>{element}</RootWrapper>
    )
}
