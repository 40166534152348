import { useEffect } from 'react'
import 'vanilla-cookieconsent/dist/cookieconsent.css'
import { run } from 'vanilla-cookieconsent'
import { collectGAnalytics } from '../services/google-analytics'

const contactEmail = 'info@koherent.io'
const cookiesDescription = 'We use cookies and similar technologies to provide you with a better online experience, to manage our marketing, track our website performance and to make our content more relevant to you.'

const config: CookieConsent.CookieConsentConfig = {
    revision: 0,
    categories: {
        functional: { enabled: true, readOnly: true },
        performance: { enabled: true },
        analytics: { enabled: true },
    },

    disablePageInteraction: false,

    language: {
        default: 'en',
        translations: {
            en: {
                consentModal: {
                    title: 'We use cookies',
                    description: cookiesDescription,
                    acceptAllBtn: 'Accept all',
                    acceptNecessaryBtn: 'Reject all',
                    showPreferencesBtn: 'Manage Individual preferences',
                },
                preferencesModal: {
                    title: 'Manage cookie preferences',
                    acceptAllBtn: 'Accept all',
                    acceptNecessaryBtn: 'Reject all',
                    savePreferencesBtn: 'Accept current selection',
                    closeIconLabel: 'Close modal',
                    sections: [
                        {
                            description: `${cookiesDescription}<br><br>If you choose to reject cookies, you may still use our websites and some services, however your access to some functionality and areas of our site or services may be restricted.`,
                        },
                        {
                            title: 'Functional cookies',
                            description: 'These cookies are necessary for the website to function properly and offers you the best user experience. Functional cookies are e.g. used for storing your privacy preferences and other information you might otherwise need to enter repeatedly. These cookies may be placed automatically, without prior consent. You can configure your browser to block these cookies, however, this may affect the functionality of the website.',
                            linkedCategory: 'functional',
                        },
                        {
                            title: 'Performance',
                            description: 'These cookies provide us with usage insights and help us to improve our website’s functionality and user experience. We ask for your consent to place this kind of cookies.',
                            linkedCategory: 'performance',
                        },
                        {
                            title: 'Analytics & Marketing',
                            description: 'These cookies are used by us for tracking user behavior across our site and/or other sites and services. The collected data can be used for generating customer profiles, displaying advertising and other similar marketing purposes. We ask for your consent to place this kind of cookies.',
                            linkedCategory: 'analytics',
                        },
                        {
                            title: 'More information',
                            description: `For further information, please contact us at <a href="mailto:${contactEmail}">${contactEmail}</a>`,
                        },
                    ],
                },
            },
        },
    },

    guiOptions: {
        consentModal: {
            equalWeightButtons: false,
        },
    },

    // Fired on the very first consent action and on each page load
    onConsent: ({ cookie }) => {
        const accepted = cookie.categories
        if (accepted.includes('analytics')) {
            collectGAnalytics()
        }
    },

    onChange: () => {
        window.location.reload()
    },
}

const useCookieConsent = () => {
    useEffect(() => {
        void run(config)
    }, [])
}

export default useCookieConsent

