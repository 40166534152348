// https://developers.google.com/tag-platform/security/guides/consent?consentmode=basic#gtag.js_1

type WindowWithDataLayer = Window & {
    dataLayer: object[]
}

export const getGTag = (): (...args: any[]) => void => {
    const w = window as unknown as WindowWithDataLayer
    w.dataLayer = w.dataLayer || []
    // eslint-disable-next-line prefer-rest-params
    return function(){w.dataLayer.push(arguments)}
}

/**
 * Initializes the Google Analytics config, with all collection
 * categories set to disabled.
 */
export const initGAnalytics = () => {
    const gtag = getGTag()

    gtag('consent', 'default', {
        'ad_storage': 'denied',
        'ad_user_data': 'denied',
        'ad_personalization': 'denied',
        'analytics_storage': 'denied',
    })
    gtag('js', new Date())
    gtag('config', process.env.GATSBY_GOOGLE_ANALYTICS_TAG_ID, {
        'anonymize_ip': true, // Required by countries such as Germany
    })

    const adsConversionTag = process.env.GATSBY_GOOGLE_ADS_CONVERSION_TAG_ID
    if (adsConversionTag) {
        gtag('config', adsConversionTag, { 'anonymize_ip': true })
    }
}

/**
 * Enable Google Analytics collection & install the gtag script
 */
export const collectGAnalytics = () => {
    const gtag = getGTag()

    gtag('consent', 'update', {
        'ad_user_data': 'granted',
        'ad_personalization': 'granted',
        'ad_storage': 'granted',
        'analytics_storage': 'granted',
    })
    const gtagScript = document.createElement('script')
    gtagScript.id = 'gtag-script'
    gtagScript.async = true
    gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GOOGLE_ANALYTICS_TAG_ID}`

    const firstScript = document.getElementsByTagName('script')[0]
    firstScript.parentNode?.insertBefore(gtagScript, firstScript)
}

/**
 * Disable Google Analytics collection
 */
export const disableGAnalytics = () => {
    const gtag = getGTag()

    gtag('consent', 'update', {
        'ad_storage': 'denied',
        'ad_user_data': 'denied',
        'ad_personalization': 'denied',
        'analytics_storage': 'denied',
    })
}
